<template>
  <div>
    <div class="row" v-if="custoSelecionado">
      <div class="col-12">
        <b-form-group label="Origem">
          <template #label> <i class="fa fa-warehouse" /> Origem </template>
          {{ custoSelecionado.nome }}
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group label="Destino">
          <template #label> <i class="fa fa-warehouse" /> Destino </template>
          <v-select :options="custos" label="nome" v-model="obj.custo" />
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group label="Adicionar Produto">
          <v-select :options="produtos" label="nome" v-model="item.produto" />
        </b-form-group>
      </div>
      <div class="col-12">
        <b-table :fields="campos" :items="produtosAdicionados" stacked="sm">
          <template #cell(transf)="row" class="text-center">
            <!-- <b-spinbutton
              v-model="row.item.transf"
              :min="1"
              :max="row.item.qtd"
              inline
              style="with:160px"
            />
            <b-btn
              @click="
                $refs['txtQtd_' + row.index].focus();
                $refs['txtQtd_' + row.index].select();
              "
              class="d-block d-sm-none"
              ><b-icon-calculator
            /></b-btn> -->

            <input
              pattern="\d*"
              type="number"
              class="form-control"
              :ref="'txtQtd_' + row.index"
              v-model="row.item.transf"
              :max="row.item.qtd"
              @blur="
                if (row.item.transf > row.item.qtd)
                  row.item.transf = row.item.qtd;
              "
            />
          </template>
        </b-table>
      </div>

      <div class="col-12 text-right">
        <hr />
        <b-btn variant="success" @click="salvar"
          ><i class="fa fa-save" /> Salvar Transferência</b-btn
        >
        <b-btn variant="secondary" @click="close"
          ><i class="fa fa-ban" /> Cancelar</b-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import CentroCustoLib from "../../libs/CentrosDeCustoLib";
import EstoqueLib from "../../libs/EstoqueLib";
// import EstoqueLib from "../../libs/EstoqueLib";

export default {
  components: {},
  props: {
    custoSelecionado: {
      type: Object,
      default: null
    },
    produtos: {
      type: Array
    }
  },
  mounted() {
    this.carregarCustos();
    if (!this.produtos || this.produtos.length <= 0) this.carregarProdutos();
    // if (this.produtos && this.produtos.length > 0) {
    //   this.produtosAdicionados = JSON.parse(JSON.stringify(this.produtos));
    // }
  },
  data() {
    return {
      custos: [],
      produtosAdicionados: [],
      obj: {
        custo: null
      },
      item: {
        produto: null
      },
      campos: [
        { key: "nome", label: "Produto" },
        { key: "qtd", label: "Estoque" },
        { key: "transf", label: "Quantidade" }
      ]
    };
  },
  watch: {
    item: {
      deep: true,
      handler() {
        if (this.item.produto) {
          this.addProduto();
        }
      }
    }
  },
  computed: {},
  methods: {
    async carregarProdutos() {
      this.produtos = (
        await EstoqueLib.getEstoqueAtual({
          cod_custo: this.custoSelecionado.cod_custo
        })
      ).filter(x => x.qtd > 0);

      //   console.log('prods',this.produtos)
    },
    addProduto() {
      // console.log("-------------", this.item);
      if (
        this.item.produto &&
        (this.item.produto.cod_produto || this.item.produto.cod_ingrediente) &&
        !this.produtosAdicionados.some(
          x =>
            (this.item.produto.cod_produto &&
              x.cod_produto === this.item.produto.cod_produto) ||
            (this.item.produto.cod_ingrediente &&
              x.cod_ingrediente === this.item.produto.cod_ingrediente)
        )
      ) {
        if (this.item.produto.qtd <= 0) {
          this.$swal("Sem estoque!", "", "error");
        } else {
          this.produtosAdicionados.push({ ...this.item.produto, transf: 1 });
        }
      }
      this.item.produto = null;
    },
    async carregarCustos() {
      this.custos = await CentroCustoLib.get();
      if (this.custoInicial > 0) {
        this.obj.custo = this.custos.find(
          x => x.cod_custo == this.custoInicial
        );
      }
    },
    // async carregarProdutos() {
    //   this.produtos = await EstoqueLib.getProdutos();
    //   //   console.log('prods',this.produtos)
    // },
    close() {
      this.$emit("close");
    },
    async salvar() {
      if (this.produtosAdicionados.length <= 0) {
        this.$swal(
          "Atenção",
          "Informe ao menos 1 produto para ser transferido!",
          "error"
        );
        return;
      }
      if (!this.obj || !this.obj.custo) {
        this.$swal("Atenção", "Informe o DESTINO da transferência", "error");
        return;
      }

      let obj = {
        origem: this.custoSelecionado,
        destino: this.obj.custo,
        itens: this.produtosAdicionados
      };
      let res = await EstoqueLib.transferir(obj);

      if (res.success) {
        this.$swal(
          "Concluído",
          "Transferência realizada com sucesso!",
          "success"
        );
        this.$emit("close");
      } else {
        this.$swal("Atenção", "Erro ao realizar transferência!", "error");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>