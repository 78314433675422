<template>
  <div>
    <div v-if="loading" class="p-5 text-center">
      <b-spinner />
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-12 pr-0 pr-sm-3 pl-0 pl-sm-3">
          <select-centro-custo
            :custoId="obj.custo"
            :showTitle="true"
            @change="(ev) => (obj.custo = ev)"
          />
        </div>
        <div class="col-12 pr-0 pr-sm-3 pl-0 pl-sm-3 mt-3">
          <select-fornecedor
            :fornecedorId="obj.fornecedor"
            :showTitle="true"
            @change="
              (ev) => {
                obj.fornecedor = ev;
              }
            "
          />
        </div>
        <div class="col-12 pr-0 pr-sm-3 pl-0 pl-sm-3">
          <hr />
          <b-form-group label="Produtos">
            <v-select :options="produtos" label="nome" v-model="item.produto" />
            <div class="row mt-2">
              <div class="col-6 pr-1">
                <b-form-group label="Valor Unitário">
                  <b-input
                    v-model="item.vl_unitario"
                    pattern="\d*"
                    v-money="moneyMask"
                  />
                </b-form-group>
              </div>
              <div class="col-6 pl-1 text-center">
                <b-form-group label="Qtd.">
                  <!-- <b-spinbutton
                  v-model="item.qtd"
                  min="1"
                  max="99999"
                  inline
                  style="min-with:160px"
                />
                <b-btn @click="$refs.txtQtd.focus(); $refs.txtQtd.select()"
                  ><b-icon-calculator
                /></b-btn> -->

                  <input
                    pattern="\d*"
                    type="number"
                    ref="txtQtd"
                    v-model="item.qtd"
                    class="form-control"
                  />
                  <div
                    v-if="item.vl_total && item.vl_total > 0"
                    class="text-right pr-2 pt-3 pb-0 mb-0"
                  >
                    <h5 class="pb-0 mb-0">
                      Total: {{ item.vl_total | currencyMask }}
                    </h5>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form-group>
          <b-btn block variant="success" @click="addItem"
            ><b-icon-plus /> Adicionar</b-btn
          >
        </div>
        <div class="col-12 mt-3">
          <label><b>Lista de Itens</b></label>
          <b-table :fields="camposItens" stacked="sm" :items="itens">
            <template #cell(vl_total)="row">
              {{ row.item.vl_total | currency }}
            </template>
            <template #cell(opc)="row">
              <b-btn small size="sm" @click="delItem(row.item)" variant="danger"
                ><b-icon-trash
              /></b-btn>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row">
        <div class="col-8 col-sm-6 p-3">
          <b-check switch v-model="obj.gerarContas" :value="true"
            >Gerar Contas Á Pagar</b-check
          >
        </div>
        <div class="col-4 col-sm-6 text-center text-sm-right pt-3">
          <h4>{{ obj.vl_total | currencyMask }}</h4>
        </div>
        <div class="col-12">
          <div v-if="obj.gerarContas">
            <br />
            <label>Vencimento</label><br />
            <b-input type="date" v-model="obj.dtVencimento" />

            <file-upload class="mt-3" :enableTitle="true" :value="obj.anexos" />
          </div>
        </div>
        <div class="col-12 text-right">
          <hr />
          <b-btn variant="success" @click="salvar"
            ><i class="fa fa-save" /> Salvar Entrada</b-btn
          >
          <b-btn variant="secondary" @click="close"
            ><i class="fa fa-ban" /> Cancelar</b-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CentroCustoLib from "../../libs/CentrosDeCustoLib";
// import FornecedoresLib from "../../libs/FornecedoresLib";
import EstoqueLib from "../../libs/EstoqueLib";
import moment from "moment";
import SelectFornecedor from "../../components/Fornecedores/SelectFornecedor.vue";
import SelectCentroCusto from "../../components/Financeiro/SelectCentroCusto.vue";
import FileUpload from "../../components/common/FileUpload.vue";
export default {
  components: { SelectFornecedor, SelectCentroCusto, FileUpload },
  props: {
    custoInicial: {
      type: Number,
      default: 0,
    },
    objInicial: {
      type: Object,
    },
  },
  mounted() {
    //this.carregarCustos();
    //this.carregarFornecedores();
    this.carregarProdutos();
  },
  data() {
    return {
      loading: false,
      fornecedores: [],
      custos: [],
      produtos: [],
      itens: [],
      obj: {
        custo: null,
        fornecedor: null,
        gerarContas: false,
        vl_subtotal: 0,
        vl_total: 0,
        dtVencimento: moment().format("YYYY-MM-DD"),
        anexos: [],
      },
      item: {
        produto: null,
        qtd: 1,
        vl_unitario: 0,
        vl_total: 0,
      },
      camposItens: [
        { key: "produto.nome", label: "Produto" },
        { key: "vl_unitario", label: "Valor" },
        { key: "qtd", label: "Qtd" },
        { key: "vl_total", label: "Total" },
        { key: "opc", label: "" },
      ],
    };
  },
  watch: {
    custoInicial: function () {
      if (this.custoInicial > 0) {
        this.obj.custo = this.custos.find(
          (x) => x.cod_custo == this.custoInicial
        );
      }
    },
    item: {
      deep: true,
      handler() {
        this.calculaValorItem();
      },
    },
  },
  computed: {},
  methods: {
    async calculaValorItem() {
      if (this.item && this.item.qtd && this.item.vl_unitario) {
        this.item.vl_total =
          parseFloat(this.item.qtd) *
          this.formatValorReal(this.item.vl_unitario);
      }
      this.obj.vl_subtotal = await this.itens.reduce((ret, vl) => {
        ret += vl.vl_total;
        return ret;
      }, 0);
      this.obj.vl_total = this.obj.vl_subtotal;
    },

    async carregarProdutos() {
      this.loading = true;
      this.produtos = await EstoqueLib.getProdutos({});
      //   console.log('prods',this.produtos)
      setTimeout(() => {
        if (this.objInicial) {
          console.log("objInicial", this.objInicial);
          this.obj = this.objInicial;
          this.itens = this.objInicial.produtos;
        }
        this.loading = false;
      }, 1000);
    },
    addItem() {
      let err = [];
      if (!this.item.produto) {
        err.push("Produto");
      }
      if (!this.item.qtd || this.item.qtd == "" || this.item.qtd <= 0) {
        err.push("Quantidade");
      }
      //   if (
      //     !this.item.vl_total ||
      //     this.item.vl_total == "" ||
      //     this.item.vl_total < 0
      //   ) {
      //     err.push("Valor");
      //   }

      if (err.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `<div class="text-left">Verifique os campos <br/><small><b>${err.map(
            (it) => {
              return `${it}<br/>`;
            }
          )}</b></small></div>`,
          toast: true,
          showConfirmButton: false,
          position: "bottom",
          icon: "error",
          timer: 1200,
        });
      } else {
        let obj = Object.assign(this.item, {});
        obj.id = this.itens.length + 1 + this.item.produto.cod_produto;
        // console.log(obj, this.itens);
        this.itens.push(obj);
        // console.log(this.itens);
        this.item = {
          produto: null,
          qtd: 1,
          vl_unitario: 0,
          vl_total: 0,
        };
        this.$forceUpdate();
      }
    },
    delItem(row) {
      // console.log("del", row);
      this.itens = this.itens.filter((x) => x.id != row.id);
      // console.log(this.itens);
      this.$forceUpdate();
    },
    close() {
      this.$emit("close");
    },
    async salvar() {
      let err = [];
      if (this.itens.length <= 0) {
        err.push("Adicione um ou mais itens");
      }
      if (!this.obj.fornecedor) {
        err.push("Selecione um fornecedor!");
      }
      if (!this.obj.custo) {
        err.push("Selecione um centro de custo!");
      }

      if (err.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `<div class="text-left">${err.join("<hr/>")}</div>`,
          icon: "error",
        });
      } else {
        this.obj = { ...this.obj, itens: this.itens };
        // console.log(11111,this.obj)
        let result = await EstoqueLib.entrada(this.obj);
        let erro = [];
        try {
          if (result.success) {
            erro = [];
            this.$swal({
              title: "Concluído com sucesso!",
              icon: "success",
              toast: true,
              showConfirmButton: false,
              position: "top-end",
              timer: 2000,
            });
          } else {
            erro.push(result.message);
          }
        } catch (er) {
          console.log(er);
          erro.push(erro);
        }

        if (erro.length <= 0) {
          this.$emit("close", erro.length <= 0);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
