<template>
  <div class="container-fluid text-left p-2">
    <barra-crud :busca="true" :onFiltrar="aplicaFiltro" :buscaAberta="true" />

    <b-tabs v-model="tabSelecionado">
      <b-tab
        v-for="p in centros"
        :active="centros.indexOf(p) == 0"
        :key="p.cod_custo"
      >
        <template #title>
          <div style="font-size: 12px">
            <i class="fa fa-industry" /> {{ p.nome }}
            <span class="alerta" v-if="p.alerta === 1"
              ><b-icon-exclamation-triangle-fill
            /></span>
          </div>
        </template>

        <estoque-menu
          style="margin-top: 10px"
          @openEntradaModal="openEntradaModal"
          :centroCusto="custoSelecionado"
          @startTransferencia="startTransferencia"
          @openSaidaModal="abrirSaida"
          @atualizar="carregarEstoque"
          @exibicao="changeExibicao"
          :exibir="exibicao"
          v-if="!loading"
        />

        <div class="row">
          <div class="col-12 col-sm-6">
            <b-card
              style="width: 100%"
              v-if="modoTransferencia"
              footer-tag="footer"
            >
              <template #header>
                <i class="fa fa-exchange-alt" /> Transferência de Produtos
              </template>
              Selecionados ({{ qtdSelecionado }})
              <br />
              <small
                ><small
                  ><i>selecione os produtos na lista para transferir</i></small
                ></small
              >
              <template #footer>
                <b-btn variant="success" @click="abrirTransferencia">
                  <i class="fa fa-exchange-alt" /> Transferir</b-btn
                >
                <b-btn variant="secondary" @click="cancelarTransferencia"
                  ><i class="fa fa-ban" /> Cancelar</b-btn
                >
              </template>
            </b-card>
          </div>
        </div>
        <div class="text-center m-3 p-2" v-if="loading">
          <b-spinner /> <br />
          calculando...
        </div>
        <b-table
          v-if="!loading"
          ref="tbEstoque"
          stacked="sm"
          :fields="campos"
          :items="estoque"
          :filter="filtro"
          selectable
          select-mode="multi"
          hover
          @row-selected="selecionarLinha"
          filter-debounce="400"
          style="font-size: 12px"
          small
        >
          <template #cell(select)="row">
            <div class="text-center">
              <b-checkbox v-model="row.item.selected" />
            </div>
          </template>
          <template #cell(qtd)="row">
            <div class="text-center">
              {{ row.item.qtd | currency }}
              <span class="alerta" v-if="row.item.alerta === 1"
                ><b-icon-exclamation-triangle-fill
              /></span>
            </div>
          </template>
          <template #cell(opcoes)="row">
            <div class="text-center">
              <b-btn @click="abrirHistorico(row.item)"
                ><b-icon-clock-history
              /></b-btn>
            </div>
          </template>
        </b-table>
      </b-tab>
      <b-tab
        :key="`addNewCR`"
        v-if="user.nivel == 0"
        :active="false"
        button-id="999"
      >
        <template #title><i class="fa fa-cogs" /> Gerenciar </template>
        <centros-de-custo
          @carregar="
            () => {
              carregarCentros();
              carregarEstoque();
            }
          "
        />
      </b-tab>
    </b-tabs>
    <b-modal
      id="modal-entrada"
      hide-footer
      title="Entrada Estoque"
      class="modalNoPadding"
      modal-class="modalNoPadding"
    >
      <estoque-entrada-form
        :custoInicial="custoSelecionado ? custoSelecionado.cod_custo : 0"
        @close="closeEntradaModal"
      />
    </b-modal>
    <b-modal
      id="modal-transferencia"
      hide-footer
      title="Transferência"
      class="modalNoPadding"
      modal-class="modalNoPadding"
    >
      <estoque-transferencia
        :custoSelecionado="custoSelecionado"
        :produtos="estoque"
        @close="closeTransferencia"
      />
    </b-modal>

    <b-modal
      id="modal-saida"
      hide-footer
      title="Saída"
      class="modalNoPadding"
      modal-class="modalNoPadding"
    >
      <estoque-saida
        :custoSelecionado="custoSelecionado"
        :produtos="estoque"
        @close="closeSaida"
      />
    </b-modal>

    <b-modal
      id="modal-historico"
      hide-footer
      title="Hisórico"
      size="lg"
      class="modalNoPadding"
      modal-class="modalNoPadding"
      @hidden="carregarEstoque"
    >
      <estoque-historico
        :produto="produtoSelecionado"
        :custoSelecionado="custoSelecionado"
      />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../../components/common/BarraCrud.vue";
import CentroCustroLib from "../../libs/CentrosDeCustoLib";
import EstoqueEntradaForm from "./EstoqueEntradaForm.vue";
import EstoqueMenu from "./EstoqueMenu.vue";
import EstoqueLib from "../../libs/EstoqueLib";
import EstoqueTransferencia from "./EstoqueTransferencia.vue";
import EstoqueSaida from "./EstoqueSaida.vue";
import EstoqueHistorico from "./EstoqueHistorico.vue";
import CentrosDeCusto from "../Configuracoes/Cadastros/CentrosDeCusto.vue";
export default {
  components: {
    BarraCrud,
    EstoqueMenu,
    EstoqueEntradaForm,
    EstoqueTransferencia,
    EstoqueSaida,
    EstoqueHistorico,
    CentrosDeCusto,
  },
  props: {},
  mounted() {
    this.carregarCentros();
    //this.carregarEstoque();
  },
  data() {
    return {
      centros: [],
      estoque: [],
      filtro: "",
      tabSelecionado: 0,
      custoSelecionado: null,
      campos: [
        { key: "nome", label: "Produto" },
        { key: "qtd", label: "Quantidade", class: "text-center" },
        { key: "opcoes", label: "Opções", class: "text-center" },
      ],
      mostraCard: false,
      modoTransferencia: false,
      qtdSelecionado: 0,
      loading: false,
      produtoSelecionado: null,
      estoqueMinimo: [],
      usuario: this.$store.state.auth.user,
      exibicao: "Todos",
    };
  },
  watch: {
    tabSelecionado: function () {
      if (this.tabSelecionado != this.centros.length) {
        console.log(
          "carregando estoque",
          this.tabSelecionado,
          this.centros.length,
          this.tabSelecionado != this.centros.length - 1
        );
        this.custoSelecionado = this.centros[this.tabSelecionado];
        this.carregarEstoque();
      }
    },
  },
  computed: {},
  methods: {
    changeExibicao(exibicao) {
      this.exibicao = exibicao;
      this.carregarEstoque();
    },
    abrirHistorico(prod) {
      this.produtoSelecionado = prod;
      this.$bvModal.show("modal-historico");
    },
    async carregarCentros() {
      if (this.usuario.nivel >= 1) {
        this.centros = [
          ...(await CentroCustroLib.get("", this.usuario.cod_empresa)),
        ];
      } else {
        this.centros = [
          { cod_custo: -1, nome: "GERAL" },
          ...(await CentroCustroLib.get()),
        ];
      }
      this.centros = this.centros.filter((x) => x.controla_estoque == 1);
      // this.centros[0].alerta = 1;
      // console.log(1, this.centros);
      // this.carregarEstoqueMinimo();
    },
    async carregarEstoqueMinimo() {
      this.estoqueMinimo = await EstoqueLib.EstoqueMinimo("-1");
      // console.log("est min", this.estoqueMinimo);
      for (let centro of this.centros) {
        centro.alerta = this.estoqueMinimo.some(
          (x) =>
            (x.cod_custo === centro.cod_custo || !x.cod_custo) &&
            x.qtd < x.estoque_minimo
        )
          ? 1
          : 0;
      }
      if (this.custoSelecionado && this.custoSelecionado.cod_custo != "-1") {
        for (let prod of this.estoque) {
          prod.alerta = this.estoqueMinimo.some(
            (x) =>
              this.custoSelecionado &&
              this.custoSelecionado.cod_custo != "-1" &&
              x.cod_custo == this.custoSelecionado.cod_custo &&
              ((prod.cod_ingrediente &&
                x.cod_ingrediente == prod.cod_ingrediente) ||
                (prod.cod_produto && x.cod_produto == prod.cod_produto)) &&
              x.qtd <= x.estoque_minimo
          )
            ? 1
            : 0;
          if (prod.alerta !== 1)
            prod.alerta = !this.estoqueMinimo.some(
              (x) =>
                this.custoSelecionado &&
                this.custoSelecionado.cod_custo != "-1" &&
                x.cod_custo == this.custoSelecionado.cod_custo &&
                ((prod.cod_ingrediente &&
                  x.cod_ingrediente == prod.cod_ingrediente) ||
                  (prod.cod_produto && x.cod_produto == prod.cod_produto))
            )
              ? 1
              : 0;
        }
      } else {
        for (let prod of this.estoque) {
          let resumoAlerta = await this.estoqueMinimo.reduce((ret, vl) => {
            let has = ret.find(
              (x) =>
                (vl.cod_produto && x.cod_produto === vl.cod_produto) ||
                (vl.cod_ingrediente && x.cod_ingrediente === vl.cod_ingrediente)
            );
            if (!has) {
              ret.push({
                cod_produto: vl.cod_produto,
                cod_ingrediente: vl.cod_ingrediente,
                qtd: vl.qtd || 0,
                estoque_minimo: vl.estoque_minimo || 0,
              });
            } else {
              has.qtd += vl.qtd;
            }
            return ret;
          }, []);

          prod.alerta = resumoAlerta.find(
            (x) =>
              ((prod.cod_ingrediente &&
                x.cod_ingrediente == prod.cod_ingrediente) ||
                (prod.cod_produto && x.cod_produto == prod.cod_produto)) &&
              x.qtd <= x.estoque_minimo
          )
            ? 1
            : 0;
        }
      }
    },
    async carregarEstoque() {
      this.loading = true;
      console.log("carregando ", this.custoSelecionado);
      if (!this.custoSelecionado) {
        this.loading = false;

        return;
      }
      this.estoque = (
        await EstoqueLib.getEstoqueAtual({
          cod_custo: this.custoSelecionado
            ? this.custoSelecionado.cod_custo
            : "-1",
          listaCusto: true,
        })
      )

        // .filter(
        //   (x) =>
        //     !this.custoSelecionado.lista_estoque_padrao ||
        //     this.custoSelecionado.lista_estoque_padrao.length <= 0 ||
        //     x.qtd > 0 ||
        //     (this.custoSelecionado.lista_estoque_padrao &&
        //       this.custoSelecionado.lista_estoque_padrao.length > 0 &&
        //       this.custoSelecionado.lista_estoque_padrao.some(
        //         (y) =>
        //           y.cod_produto == x.cod_produto &&
        //           y.cod_ingrediente == x.cod_ingrediente
        //       )) ||
        //     x.qtd > 0
        // )
        .filter((x) =>
          this.exibicao == "Com Estoque"
            ? x.qtd > 0
            : this.exibicao == "Sem Estoque"
            ? (x) => x.qtd <= 0
            : true
        );

      // await this.carregarEstoqueMinimo();

      this.loading = false;
      console.log(123, this.estoque);

      // console.log("estoque", this.estoque);
    },
    openEntradaModal() {
      this.$bvModal.show("modal-entrada");
    },
    closeEntradaModal() {
      this.$bvModal.hide("modal-entrada");
      this.carregarEstoque();
    },
    selecionarLinha(row) {
      //if (this.modoTransferencia) {
      this.estoque.forEach((it) => {
        it.selected = false;
      });
      for (let r of row) {
        r.selected = true;
      }
      this.qtdSelecionado = row.length;
      //this.$forceUpdate();
      //}
    },
    abrirTransferencia() {
      this.$bvModal.show("modal-transferencia");
    },
    closeTransferencia() {
      this.$bvModal.hide("modal-transferencia");
      this.carregarEstoque();
    },
    abrirSaida() {
      this.$bvModal.show("modal-saida");
    },
    closeSaida() {
      this.$bvModal.hide("modal-saida");
      this.carregarEstoque();
    },
    startTransferencia() {
      this.abrirTransferencia();

      // if (!this.modoTransferencia) {
      //   // this.estoque.forEach(it => {
      //   //   it.selected = false;
      //   // });
      //   this.modoTransferencia = true;
      //   this.campos = [
      //     {
      //       key: "select",
      //       label: ""
      //     },
      //     ...this.campos
      //   ];
      //   // this.$refs.tbEstoque.clearSelected();
      // }
      //this.$forceUpdate();
    },
    cancelarTransferencia() {
      this.modoTransferencia = false;
      this.campos = this.campos.filter((x) => x.key != "select");
      this.$forceUpdate();
      // this.estoque.forEach(it => {
      //   it.selected = false;
      // });
      // this.$refs.tbEstoque.clearSelected()
    },
    aplicaFiltro(filtro) {
      this.filtro = filtro;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  position: relative !important;
}
.alerta {
  color: red;
}
</style>
