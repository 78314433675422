<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav align="center">
          <b-nav-item href="#" @click="$emit('openEntradaModal')"
            ><b-icon-cart-plus /> Entrada</b-nav-item
          >
          <b-nav-item
            href="#"
            :disabled="disabledOptions"
            @click="$emit('startTransferencia')"
            ><i class="fa fa-exchange-alt" /> Transferência</b-nav-item
          >

          <b-nav-item
            href="#"
            :disabled="disabledOptions"
            @click="$emit('openSaidaModal')"
            ><b-icon-cart-dash /> Saída</b-nav-item
          >
          <b-nav-item
            href="#"
            :disabled="disabledOptions"
            @click="$emit('atualizar')"
            ><b-icon-recycle /> Atualizar</b-nav-item
          >
          <!-- <b-nav-item href="#"><b-icon-calculator /> Ajuste/Balanço</b-nav-item> -->
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-text class="float-right" right>
            <b-radio-group
              :options="['Todos', 'Com Estoque', 'Sem Estoque']"
              label="Exibir"
              v-model="exibicao"
            ></b-radio-group>
          </b-nav-text>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    centroCusto: {
      type: Object,
      default: null,
    },
    exibir:String
  },
  mounted() {
    if (this.exibicao != this.exibir) {
      this.exibicao = this.exibir;
    }
  },
  data() {
    return {
      disabledOptions: false,
      exibicao: "Todos",
    };
  },
  watch: {
    centroCusto: {
      deep: true,
      handler() {
        this.disabledOptions =
          !this.centroCusto || this.centroCusto.cod_custo <= 0;
      },
    },
    exibicao: function () {
      if(this.exibir != this.exibicao)
        this.$emit('exibicao', this.exibicao);
    }
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
