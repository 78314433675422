var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"mb-0 pb-0"},[_vm._v(_vm._s(_vm.produto.nome))]),_c('small',[_c('b',[_vm._v("Estoque: "+_vm._s(_vm.resumo.qtd))]),_c('small',[_vm._v(" - "+_vm._s(_vm._f("currencyMask")(_vm.resumo.total)))])])]),_c('b-btn',{staticClass:"ml-2 float-right",attrs:{"variant":"secondary"},on:{"click":_vm.carregar}},[_c('b-icon-recycle'),_vm._v(" Atualzar Tela ")],1),(_vm.loading)?_c('div',{staticClass:"text-center p-3"},[_c('b-spinner'),_vm._v(" atualizando... ")],1):_vm._e(),_c('hr'),_c('b-tabs',{attrs:{"fill":""}},[_c('b-tab',{attrs:{"title":"Movimentação"}},[_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"hover":"","items":_vm.movimentos,"small":"","tbody-tr-class":_vm.rowClass,"stacked":"sm","fields":[
          { key: 'data', label: 'Data', class: 'text-center' },
          { key: 'fornecedor', label: 'Fornecedor/Origem' },
          { key: 'tipo', label: 'Tipo' },
          {
            key: 'local',
            label: 'Destino',
            class:
              'text-center ' +
              (!_vm.custoSelecionado || _vm.custoSelecionado.cod_custo === -1
                ? ''
                : ''),
          },
          { key: 'obs', label: 'Obs' },

          { key: 'qtd', label: 'Quantidade', class: 'text-center' },
          { key: 'vl_unitario', label: 'Valor UN.', class: 'text-center' },
          { key: 'vl_total', label: 'Total', class: 'text-center' },
          { key: 'usuario', label: 'Usuário', class: 'text-center' },
          { key: 'btExc', label: '' },
        ]},scopedSlots:_vm._u([{key:"cell(data)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.data,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"cell(fornecedor)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.tipo == "E" ? row.item.fornecedor : row.item.origem || row.item.local ? row.item.origem || row.item.local : "")+" ")]}},{key:"cell(tipo)",fn:function(row){return [_c('div',{staticClass:"text-left"},[_c('i',{class:`fa fa-${
                row.item.tipo == 'DE' || row.item.tipo == 'DS'
                  ? 'trash'
                  : row.item.tipo == 'TS' || row.item.tipo == 'TE'
                  ? 'arrows-alt-h ' +
                    (row.item.tipo == 'TS' ? 'text-danger' : 'text-success')
                  : row.item.tipo == 'E' || row.item.tipo == 'TE'
                  ? 'arrow-up text-success'
                  : 'arrow-down text-danger'
              }`,staticStyle:{"font-size":"20px","vertical-align":"text-top"}}),_vm._v(" "+_vm._s(row.item.tipo == "DS" ? "Saída Exc." : row.item.tipo == "DE" ? "Entrada Exc." : row.item.tipo == "TS" ? "Transf. Saída" : row.item.tipo == "TE" ? "Transf. Entrada" : row.item.tipo == "E" || row.item.tipo == "TE" ? "Entrada" : "Saída")+" "+_vm._s(_vm._f("moment")(row.item.deleted_at,"DD/MM/YYYY HH:mm"))+" "+_vm._s(row.item.usuDel)+" ")])]}},{key:"cell(local)",fn:function(row){return [(row.item.tipo == 'TS')?_c('span',[_vm._v(" "+_vm._s(row.item.destino)+" ")]):_c('span',[_vm._v(" "+_vm._s(row.item.local)+" ")])]}},{key:"cell(qtd)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.qtd))+" ")]}},{key:"cell(vl_unitario)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_unitario))+" ")])]}},{key:"cell(vl_total)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_total))+" ")])]}},{key:"cell(btExc)",fn:function(row){return [(row.item.tipo.indexOf('D') < 0)?_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.del(row.item.id)}}},[_c('b-icon-trash')],1):_vm._e()]}}])})],1),_c('b-tab',{attrs:{"title":"Estoque por Preço"}},[_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"items":_vm.listaPrecos,"fields":[
          {
            key: 'local',
            label: 'Local',
            class:
              'text-center ' +
              (!_vm.custoSelecionado || _vm.custoSelecionado.cod_custo === -1
                ? ''
                : 'd-none'),
          },
          { key: 'estoque', label: 'Qtd.', class: 'text-center' },
          { key: 'vl_unitario', label: 'Valor', class: 'text-center' },
          { key: 'tot', label: 'Valor Total', class: 'text-center' },
        ],"foot-clone":"","small":"","stacked":"sm"},scopedSlots:_vm._u([{key:"cell(local)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.item.local)+" ")])]}},{key:"cell(estoque)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("currency")(row.item.estoque))+" ")])]}},{key:"cell(vl_unitario)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_unitario))+" ")])]}},{key:"cell(tot)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("currency")((row.item.estoque * row.item.vl_unitario)))+" ")])]}},{key:"foot(local)",fn:function(data){return [_c('span',{attrs:{"i":data}},[_vm._v("Totais")])]}},{key:"foot(estoque)",fn:function(data){return [_c('span',{attrs:{"i":data}},[_vm._v(_vm._s(_vm._f("currency")(_vm.resumo.qtd)))])]}},{key:"foot(vl_unitario)",fn:function(data){return [_c('span',{attrs:{"i":data}})]}},{key:"foot(tot)",fn:function(data){return [_c('span',{attrs:{"i":data}},[_vm._v(_vm._s(_vm._f("currency")(_vm.resumo.total)))])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }