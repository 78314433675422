<template>
  <div>
    <div class="text-center">
      <h3 class="mb-0 pb-0">{{ produto.nome }}</h3>
      <small
        ><b>Estoque: {{ resumo.qtd }}</b>
        <small> - {{ resumo.total | currencyMask }}</small>
      </small>
    </div>
    <b-btn variant="secondary" class="ml-2 float-right" @click="carregar">
      <b-icon-recycle /> Atualzar Tela
    </b-btn>
    <div class="text-center p-3" v-if="loading">
      <b-spinner /> atualizando...
    </div>
    <hr />
    <b-tabs fill>
      <b-tab title="Movimentação">
        <b-table
          hover
          style="font-size: 10px"
          :items="movimentos"
          small
          :tbody-tr-class="rowClass"
          stacked="sm"
          :fields="[
            { key: 'data', label: 'Data', class: 'text-center' },
            { key: 'fornecedor', label: 'Fornecedor/Origem' },
            { key: 'tipo', label: 'Tipo' },
            {
              key: 'local',
              label: 'Destino',
              class:
                'text-center ' +
                (!custoSelecionado || custoSelecionado.cod_custo === -1
                  ? ''
                  : ''),
            },
            { key: 'obs', label: 'Obs' },

            { key: 'qtd', label: 'Quantidade', class: 'text-center' },
            { key: 'vl_unitario', label: 'Valor UN.', class: 'text-center' },
            { key: 'vl_total', label: 'Total', class: 'text-center' },
            { key: 'usuario', label: 'Usuário', class: 'text-center' },
            { key: 'btExc', label: '' },
          ]"
        >
          <template #cell(data)="row">
            {{ row.item.data | moment("DD/MM/YYYY HH:mm") }}
          </template>
          <template #cell(fornecedor)="row">
            {{
              row.item.tipo == "E"
                ? row.item.fornecedor
                : row.item.origem || row.item.local
                ? row.item.origem || row.item.local
                : ""
            }}
          </template>
          <template #cell(tipo)="row">
            <div class="text-left">
              <i
                style="font-size: 20px; vertical-align: text-top"
                :class="`fa fa-${
                  row.item.tipo == 'DE' || row.item.tipo == 'DS'
                    ? 'trash'
                    : row.item.tipo == 'TS' || row.item.tipo == 'TE'
                    ? 'arrows-alt-h ' +
                      (row.item.tipo == 'TS' ? 'text-danger' : 'text-success')
                    : row.item.tipo == 'E' || row.item.tipo == 'TE'
                    ? 'arrow-up text-success'
                    : 'arrow-down text-danger'
                }`"
              />
              {{
                row.item.tipo == "DS"
                  ? "Saída Exc."
                  : row.item.tipo == "DE"
                  ? "Entrada Exc."
                  : row.item.tipo == "TS"
                  ? "Transf. Saída"
                  : row.item.tipo == "TE"
                  ? "Transf. Entrada"
                  : row.item.tipo == "E" || row.item.tipo == "TE"
                  ? "Entrada"
                  : "Saída"
              }}
              {{ row.item.deleted_at | moment("DD/MM/YYYY HH:mm") }}
              {{ row.item.usuDel }}
            </div>
          </template>
          <template #cell(local)="row">
            <span v-if="row.item.tipo == 'TS'">
              {{ row.item.destino }}
            </span>
            <span v-else>
              {{ row.item.local }}
            </span>
          </template>
          <template #cell(qtd)="row">
            {{ row.item.qtd | currency }}
          </template>
          <template #cell(vl_unitario)="row">
            <div class="text-center">
              {{ row.item.vl_unitario | currency }}
            </div>
          </template>
          <template #cell(vl_total)="row">
            <div class="text-center">
              {{ row.item.vl_total | currency }}
            </div>
          </template>
          <template #cell(btExc)="row">
            <button
              class="btn btn-sm btn-danger"
              v-if="row.item.tipo.indexOf('D') < 0"
              @click="del(row.item.id)"
            >
              <b-icon-trash />
            </button>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Estoque por Preço">
        <b-table
          :items="listaPrecos"
          :fields="[
            {
              key: 'local',
              label: 'Local',
              class:
                'text-center ' +
                (!custoSelecionado || custoSelecionado.cod_custo === -1
                  ? ''
                  : 'd-none'),
            },
            { key: 'estoque', label: 'Qtd.', class: 'text-center' },
            { key: 'vl_unitario', label: 'Valor', class: 'text-center' },
            { key: 'tot', label: 'Valor Total', class: 'text-center' },
          ]"
          foot-clone
          small
          stacked="sm"
          style="font-size: 10px"
        >
          <template #cell(local)="row">
            <div class="text-center">
              {{ row.item.local }}
            </div>
          </template>
          <template #cell(estoque)="row">
            <div class="text-center">
              {{ row.item.estoque | currency }}
            </div>
          </template>
          <template #cell(vl_unitario)="row">
            <div class="text-center">
              {{ row.item.vl_unitario | currency }}
            </div>
          </template>
          <template #cell(tot)="row">
            <div class="text-center">
              {{ (row.item.estoque * row.item.vl_unitario) | currency }}
            </div>
          </template>

          <template #foot(local)="data">
            <span class="" :i="data">Totais</span>
          </template>
          <template #foot(estoque)="data">
            <span class="" :i="data">{{ resumo.qtd | currency }}</span>
          </template>
          <template #foot(vl_unitario)="data">
            <span class="" :i="data"></span>
          </template>
          <template #foot(tot)="data">
            <span class="" :i="data">{{ resumo.total | currency }}</span>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import EstoqueLib from "../../libs/EstoqueLib";
export default {
  components: {},
  props: {
    produto: Object,
    custoSelecionado: Object,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      loading: false,
      listaPrecos: [],
      movimentos: [],
      resumo: {
        qtd: 0,
        total: 0,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    async del(id) {
      this.$swal({
        title: "Confirmação",
        text: "Você tem certeza que deseja excluir este movimento? Não será possível reverter a ação.",
        icon: "question",
        type: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim. Excluir!",
        cancelButtonText: "Não. Cancelar",
      }).then(async (res) => {
        if (res.isConfirmed) {
          await EstoqueLib.excluirMovimento(id);
          await this.carregar();
        }
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.tipo.indexOf("D") >= 0) {
        console.log(1111);
        return "linhaExcluida";
      }
    },
    async carregar() {
      this.loading = true;
      let res = (
        await EstoqueLib.Historico({
          produto: this.produto,
          custo: this.custoSelecionado,
        })
      ).data;

      // console.log(res);

      this.listaPrecos = res.listaPreco;
      this.resumo = await res.listaPreco.reduce(
        (ret, vl) => {
          ret.qtd += vl.estoque;
          ret.total += vl.estoque * vl.vl_unitario;
          return ret;
        },
        { qtd: 0, total: 0 }
      );

      this.movimentos = res.movimento.map((it) => ({
        ...it,
      }));

      console.log(res);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.linhaExcluida {
  opacity: 0.6 !important;
  color: red !important;
  font-size: 9px;
  td {
    padding: 0px;
    i {
      font-size: 9px !important;
    }
  }
}
</style>
